main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}

.sdk-select {
    height: 34px;
    border-radius: 4px;
}

.websdktest button {
    float: right;
    margin-left: 5px;
}
#nav-tool {
    margin-bottom: 0px;
}

#nav-tool-strip {
    margin-bottom: 0px;
    align-items: center;
    place-content: center;
}

#show-test-tool {
    position: absolute;
    top: 100px;
    left: 0;
    display: block;
    z-index: 99999;        
}
#display_name {
    width: 250px;
}

.text-white {
    color:#FFFFFF;
}


#websdk-iframe {
    width: 700px;
    height: 500px;
    border: 1px;
    border-color: red;
    border-style: dashed;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin: 0;
}

.btn-white {
    box-shadow: 0 4px 20px #00000040;
    border-radius: 4px;
    border: 1px solid #091A31;
    background: #FFFFFF;
    padding: 10px 60px;
    color: #091a31;
    letter-spacing: .005em;
    font-weight: 800;
    font-size: 16px;
    font-family: Roboto,sans-serif;
}
#dol-logo {
    height: 100% !important; 
    width: auto !important;
}

.bg-pri {
    background-color: #f25623 !important;
}

#join-btn {
    background-color: #f25623 !important;
    border-color: #f25623 !important;
    height: 46px !important;
    line-height: inherit !important;
    color: #fff !important;
    font-size: 18px !important;
    text-align: center !important;
    min-width: 200px !important;
}

.bg-whi {
    background-color: #fff !important;
}

#header-text {
    color: #fff !important; 
    font-weight: 600 !important;
}

.color-pri {
    color: #f25623 !important;
}

.color-whi {
    color: #fff !important;
}

.f-weight-900 {
    font-weight: 900;
}

.f-size-1 {
    font-size: 1rem;
}

.f-size-3 {
    font-size: 3rem;
}

.meeting-details {
    height: 100vh; 
    display: flex; 
    place-content: center; 
    align-items: center; 
    justify-content: center; 
    background-color: #fff;
    width: 100%;
}

#nav-header {
    display: flex; 
    align-items: center; 
    margin: 10px auto;
}

.margin-center {
  margin: 0 auto;
}

.logo {
  height: 3rem; 
  margin-right: 1rem;
}